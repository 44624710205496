import React from "react"
import { graphql , Link} from 'gatsby'
import Layout from '../components/layout.jsx'

const Category = props => {
  const {pageContext, data} = props 
  const { postByCategory } = pageContext
  return (
    <Layout title={data.site.siteMetadata.title}>
    <ul>
      {postByCategory.map((data, index) => {
          return (
          <li key={index}>
              <Link to={`/categories/${data.categoryName}`}>
                {data.categoryName}({data.postCount})    
              </Link>
          </li>
          )
        })}
    </ul>
  </Layout>
  )
}

export const query = graphql`
  query {
	  site {
   	  siteMetadata {
        title
 	    }
    }
  }
`

export default Category